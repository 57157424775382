form {
    display: flex;
    flex-direction: column;
    width: 300px;
    
}

form > * {
    padding: 5px
}

button:hover {
    background-color: lightblue
}

button:disabled:hover {
    background-color: red;
}


